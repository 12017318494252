@import './../../styles.scss';

.reverse-row-order{
  flex-direction: row-reverse;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: inherit;
}

.content h1 {
  font-family: $family-secondary;
  font-size: $size-1;
  font-weight: 800;
}

.content h2 {
  font-family: $family-secondary;
  font-size: $size-2;
  font-weight: 800;
  text-align: center;
  margin-bottom: $padding-medium;
}

.content h3 {
  font-family: $family-secondary;
  font-size: $size-3;
  font-weight: 700;
  margin-bottom: $padding-small;
}

.content h4 {
  font-family: $family-primary;
  font-size: $size-4;
  font-weight: 700;
  margin-bottom: $padding-small;
}

.content p {
  font-family: $family-primary;
  font-weight: 400;
  line-height: 1.6;
}

.content ul {
  margin-top: 0;
}

.content a {
  color: inherit;
  padding-bottom: 2px;
  border-bottom: 1px dashed;
}

header.is-active {
  i.fa-angle-up {
    display: initial;
  }

  i.fa-angle-down {
    display: none;
  }
}

header:not(.is-active) {
  i.fa-angle-up {
    display: none;
  }

  i.fa-angle-down {
    display: initial;
  }
}

$marginKey: 'margin';
$paddingKey: 'padding';
$separator: '-';
$spacingSizes: (
  ('none', 0rem),
  ('small', $padding-small),
  ('medium', $padding-medium),
  ('large', $padding-large),
);
$positions: (
    ('top', 'top'),
    ('right', 'right'),
    ('bottom', 'bottom'),
    ('left', 'left')
);

@function sizeValue($key, $value) {
    @return if($key == 'none', 0, $value);
}

@each $size in $spacingSizes {
    $sizeKey: nth($size, 1);
    $sizeValue: nth($size, 2);
    .has#{$separator}#{$marginKey}#{$separator}#{$sizeKey} {
        margin: sizeValue($sizeKey, $sizeValue) !important;
    }
    .has#{$separator}#{$paddingKey}#{$separator}#{$sizeKey} {
        padding: sizeValue($sizeKey, $sizeValue) !important;
    }
    @each $position in $positions {
        $posKey: nth($position, 1);
        $posValue: nth($position, 2);
        .has#{$separator}#{$marginKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            margin-#{$posValue}: sizeValue($sizeKey, $sizeValue) !important;
        }
        .has#{$separator}#{$paddingKey}#{$separator}#{$posKey}#{$separator}#{$sizeKey} {
            padding-#{$posValue}: sizeValue($sizeKey, $sizeValue) !important;
        }
    }
}
