@charset 'utf-8';

// COLORS
$primary: #B721FF;
$secondary: #21D4FD;
$info: #03A9F4;
$success: #23D160;
$warning: #FFDD57;
$danger: #FF3860;
$light: #F5F5F5;
$dark: #363636;
$text: #4A4A4A;
$link: #3273DC;

// TEXT
$body-family: 'Lato', sans-serif;
$family-primary: 'Lato', sans-serif;
$family-secondary: 'Dosis', sans-serif;
$family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// BREAKPOINTS
$gap: 96px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// SIZES
$body-size: 24px;
$size-1: 3.00rem;
$size-2: 2.80rem;
$size-3: 1.60rem;
$size-4: 1.30rem;
$size-5: 0.90rem;
$size-6: 0.75rem;
$size-7: 0.60rem;

// LAYOUT
$tooltip-max-width: 8rem;
$padding-small: 0.75rem;
$padding-medium: 3rem;
$padding-large: 6rem;
$section-padding: $padding-small 1.5rem;
$section-padding-medium: $padding-medium 1.5rem;
$section-padding-large: $padding-large 1.5rem;
$footer-padding: $padding-medium 1.5rem;

// TITLES
$title-size: $size-1;
$title-family: $family-secondary;
$title-weight: 800;
$subtitle-size: $size-4;
$subtitle-family: $family-primary;
$subtitle-weight: 400;

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

$skin1: #FEC74A;
$skin2: #F9DBBD;
$skin3: #DFBA97;
$skin4: #BD8F6B;
$skin5: #9A6440;
$skin6: #59453A;
$skin1-invert: findColorInvert($skin1);
$skin2-invert: findColorInvert($skin2);
$skin3-invert: findColorInvert($skin3);
$skin4-invert: findColorInvert($skin4);
$skin5-invert: findColorInvert($skin5);
$skin6-invert: findColorInvert($skin6);

@import "~bulma/sass/utilities/derived-variables";
$addColors: (
  "skin1": ($skin1, $skin1-invert),
  "skin2": ($skin2, $skin2-invert),
  "skin3": ($skin3, $skin3-invert),
  "skin4": ($skin4, $skin4-invert),
  "skin5": ($skin5, $skin5-invert),
  "skin6": ($skin6, $skin6-invert),
);
$colors: map-merge($colors, $addColors);

// IMPORT BULMA
@import 'bulma';
@import '~bulma-tooltip/dist/css/bulma-tooltip.sass';
@import '~@creativebulma/bulma-collapsible';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
